@use '@angular/material/core/m2/theming' as mat
@use 'styling/colors/mementor-colors' as mementor_colors

@mixin challege-theme($config-or-theme)

  $config: mat.get-color-config($config-or-theme)

  $primary: map-get($config, primary)

  // toggle button
  #challengeForm
    .main-title, .title
      font-size: 20px
      color: map-get($primary, 500) !important
      font-weight: 400 !important
      width: 100%
      text-align: center

    .title
      font-size: 16px

    .day-wrapper
      border: 0.5px solid #8080804a
      border-radius: 5px
      max-width: 140px
      width: 100%
      height: 35px
      background-color: mementor_colors.$secondary-light
      display: grid
      grid-template-columns: 65% 35%
      gap: 8px
      padding: 0 2px
      align-items: center
      @media screen and (max-width: 390px)
        max-width: 120px


    .add-time-wrapper
      border-radius: 50px
      background-color: map-get($primary, 500)
      width: 30px
      height: 30px
      display: flex
      align-items: center
      justify-content: center
      justify-self: end
      margin-right: 10px

    .remove-time
      width: 34px
      height: 34px
      margin: 1px 0 0 -8px
      color: map-get($primary, 500)

