@use '@angular/material/core/m2/theming' as mat
@use 'styling/functions' as functions

@mixin sleep-journal-evaluation-component-theme($config-or-theme)

  // extract the color configuration in case a theme has been passed.
  $config: mat.get-color-config($config-or-theme)

  // extract the palettes needed from the theme definition.
  $primary: map-get($config, primary)
  $accent: map-get($config, accent)

  .tab-header
    .tab-header-item.active
      border-bottom: 2px solid mat.get-color-from-palette($primary, 500)

  #evaluation-wrapper
    #evaluation-selector
      ::ng-deep .mat-mdc-select-value
        color: mat.get-color-from-palette($primary, 500) !important

  .property-picker.primary
    .marker
      background: mat.get-color-from-palette($primary, 500)

  .property-picker.secondary
    .marker
      background: mat.get-color-from-palette($accent, 500)

  #summary-wrapper
    .summary-item
      color: mat.get-color-from-palette($primary, 500)

      .value
        font-family: map-get($config-or-theme, headlineFont)

  .detail-wrapper
    .edit-button
      border-radius: functions.map-deep-get($config-or-theme, boxRadius, large)
      width: 100%

  .direction.improve
    color: mat.get-color-from-palette($primary, 500)

  .direction.worsen
    color: mat.get-color-from-palette($accent, 500)

  .direction.even
    color: #aeaeae
  #evaluation-wrapper
    #graph-wrapper
      ::ng-deep .mat-mdc-tab-label
        font-family: map-get($config, headlineFont)

  .trash-button-wrapper
    border-radius: 50%
    background-color: mat.get-color-from-palette($accent, 500)

    button
      line-height: 15px

  .overview-widget
    .widget-item
      .value
        font-family: map-get($config-or-theme, headlineFont)
        color: mat.get-color-from-palette($primary, 500)

        .mat-icon.active
          color: mat.get-color-from-palette($primary, 500) !important
