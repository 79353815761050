@use '@angular/material/core/m2/theming' as mat

@mixin admin-theme($config-or-theme)

  $config: mat.get-color-config($config-or-theme)

  $primary: map-get($config, primary)

  // toggle button
  .single-toggle-button
    color: map-get($primary, 500) !important
    font-weight: 500 !important
    font-size: 14px
    border: none !important
    padding: 0 3px


    button
      span
        mat-icon
          margin-right: 5px
