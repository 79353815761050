@use '@angular/material/core/m2/theming' as mat

@mixin mood-visualiser-component-theme($config-or-theme)

  $config: mat.get-color-config($config-or-theme)
  $primary: map-get($config, primary)
  $accent: map-get($config, accent)

  #value-triangle
    fill: map-get($primary, 500)


