@use '@angular/material/core/m2/theming' as mat
@use 'styling/colors/mementor-colors' as mementor_colors


@mixin diabetes-journal-component-theme($config-or-theme)
  $config: mat.get-color-config($config-or-theme)
  $primary: map-get($config, primary)
  $accent: map-get($config, accent)

  #diabetes-journal
    .diabetes-journal-input-dialog
      h3
        color: map-get($primary, 500)
        text-align: center

    .protocol-tab-button
      background-color: mementor_colors.$secondary-light
      color: mementor_colors.$secondary
      box-shadow: none

    .protocol-tab-button.active
      background-color: mementor_colors.$secondary
      color: white

    .upper-section
      padding: 15px 10px
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16)
      border-bottom-right-radius: 20px
      border-bottom-left-radius: 20px
      background: white

      @media screen and (min-width: 400px)
        padding: 15px 20px

    .middle-section
      .button-wrapper
        display: flex
        justify-content: center
        flex-direction: column
        align-items: stretch
        gap: 0.4em
        margin-bottom: 1.5em

        h3
          text-align: center
          margin: 0

    .lower-section, .middle-section
      background-color: mementor_colors.$secondary-light
      padding: 1em  min(31px, 4vw)
      border-radius: 20px

    .lower-section
      border-bottom-right-radius: 0
      border-bottom-left-radius: 0

      @media (min-width: 415px)
          border-bottom-right-radius: 20px
          border-bottom-left-radius: 20px

    .select-wrapper
      display: flex
      flex-direction: column
      gap: 5px
      margin-bottom: 1rem

      @media screen and (min-width: 400px)
        flex-direction: row
        gap: 20px
