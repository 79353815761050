@use '@angular/material/core/m2/theming' as mat

@mixin header-component-theme($config-or-theme)

  $config: mat.get-color-config($config-or-theme)

  $primary: map-get($config, primary)
  $accent: map-get($config, accent)
  $gradient: map-get($config-or-theme, gradient)

  #header-wrapper
    .title, .gray
      color: #666
