@use '@angular/material/core/m2/theming' as mat
@use 'styling/colors/mementor-colors' as mementor_colors

@mixin perception-theme($config-or-theme)
  $config: mat.get-color-config($config-or-theme)
  $primary: map-get($config, primary)
  $accent: map-get($config, accent)

  .perception-screen
    overflow-y: auto
    overflow-x: hidden
    min-height: calc(100% - 55px)
    box-sizing: border-box
    display: flex
    flex-direction: column
    align-items: center

    .placeholder
      display: none

    @media screen and (min-height: 900px)
      justify-content: space-around

      .placeholder
        display: block

    > .perception-content-wrapper
      min-height: min(calc(100% - 25px), 900px)
      padding: 0
      box-sizing: border-box
      overflow: hidden
      display: flex
      width: 100%
      max-width: 600px

      &.mat-mdc-card
        border-top-left-radius: 0
        border-top-right-radius: 0

        @media screen and (min-height: 900px)
          border-radius: 20px

      &.thin-width
        max-width: 460px

    .mat-mdc-tab-body-wrapper, .mat-mdc-tab-group
      flex-grow: 1

    .secondary-background
      background-color: mementor_colors.$secondary-light
      padding: 1em min(31px, 4vw)
      border-radius: 20px
