@use '@angular/material/core/m2/theming' as mat

@mixin step-manager-component-theme($config-or-theme)

  // extract the color configuration in case a theme has been passed.
  $config: mat.get-color-config($config-or-theme)

  // extract the palettes needed from the theme definition.
  $primary: map-get($config, primary)
  $accent: map-get($config, accent)

  .asana-loading, .asana-not-relevant
    color: #aaa

  .asana-progress
    color: #dc3545

  .asana-ready-to-test
    color: #fd7e14

  .asana-completed, .asana-tested
    color: #198754

  .asana-backlog
    color: #136efd

  .asana-other-section
    color: #888

  .status-loading-spinner
    position: absolute
    left: 49px
    top: -13px
    color: #888888
    opacity: 0.8
