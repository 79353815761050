@use '@angular/material/core/m2/theming' as mat

@mixin audio-player-component-theme($config-or-theme)

  $config: mat.get-color-config($config-or-theme)

  $primary: map-get($config, primary)
  $accent: map-get($config, accent)
  $warn: map-get($config, warn)

  .audio-player
    input[type=range]::-webkit-slider-runnable-track
      background: mat.get-color-from-palette($primary, 100)

    input[type=range]:focus::-webkit-slider-runnable-track
      background: mat.get-color-from-palette($primary, 100)

    input[type=range]::-moz-range-track
      background: mat.get-color-from-palette($primary, 100)

    input[type=range]::-ms-fill-lower
      background: mat.get-color-from-palette($primary, 100)

    input[type=range]:focus::-ms-fill-lower
      background: mat.get-color-from-palette($primary, 100)

    input[type=range]::-ms-fill-upper
      background: mat.get-color-from-palette($primary, 100)

    input[type=range]:focus::-ms-fill-upper
      background: mat.get-color-from-palette($primary, 100)

    input[type=range]::-webkit-slider-thumb
      background: mat.get-color-from-palette($primary, 600)

    input[type=range]::-moz-range-thumb
      background: mat.get-color-from-palette($primary, 600)

    input[type=range]::-ms-thumb
      background: mat.get-color-from-palette($primary, 600)

    button.play-pause-button
      background-color: mat.get-color-from-palette($primary, 600)





