@use '@angular/material' as mat
@use 'sass:map'
@use '@angular/material/core/m2/palette' as palette
@use '@angular/material/core/m2/theming' as m2_theming
@use 'styling/colors/legacy-colors' as legacy_colors

// make sure to only call once
@include mat.core()

// hack that allow secondary color to be defined in a theme and changed dynamically
// this is only necessary since vars are imperative in Sass and don't change their
// value once compiled
$secondary: var(--secondary-color)
$secondary-light: var(--secondary-light-color)

// import themes
@import 'themes/somnio-theme'
@import 'themes/somnio-junior-theme'
@import 'themes/actensio-theme'
@import 'themes/glykio-theme'
@import 'themes/malio-theme'

// import theme components
@import '../app/app.component-theme'
@import '../app/modules/admin/admin-theme'
@import '../app/modules/admin/step-manager/step-manager.component-theme'

@import '../app/modules/session/session.component-theme'
@import '../app/modules/session/login/carousel/carousel.component-theme'
@import '../app/modules/coaching/navigation/navigation.component-theme'
@import '../app/modules/coaching/medication/medication.component-theme'
@import '../app/modules/coaching/dashboard/dashboard.component-theme'
@import '../app/modules/coaching/profile/profile.component-theme'
@import '../app/modules/coaching/license/license.component-theme'
@import '../app/modules/coaching/monitor/monitor.component-theme'
@import '../app/shared/dialog/dialog-header/dialog-header.component-theme'
@import '../app/shared/header/header.component-theme'
@import '../app/modules/coaching/module-stepper/module-stepper.component-theme'
@import '../app/modules/coaching/hypertension-journal/hypertension-journal.component-theme.sass'
@import '../app/modules/coaching/coach-switcher/coach-switcher.component-theme'
@import '../app/shared/audio-player/audio-player.component-theme'

@import '../app/modules/coaching/module-stepper/coach-questionnaire/coach-questionnaire.component-theme'
@import '../app/modules/coaching/profile/confirm-tracker-connection/thryve-connection-overview.component'

@import '../app/modules/coaching/sleep-journal/sleep-journal-evaluation/sleep-journal-evaluation.component-theme'
@import '../app/modules/coaching/sleep-journal/sleep-journal-evaluation/sleep-journal-week-view/sleep-journal-week-view.component-theme'
@import '../app/modules/coaching/sleep-journal/sleep-journal-entry-editor/sleep-journal-entry-visualiser/sleep-journal-entry-visualiser.component-theme'
@import '../app/modules/coaching/sleep-journal/sleep-journal-evaluation/awake-journal-week-view/awake-journal-week-view.component-theme'
@import '../app/modules/coaching/sleep-journal/sleep-journal-evaluation/mood-visualiser/mood-visualiser.component-theme'
@import '../app/modules/coaching/sleep-journal/sleep-journal-entry-editor/sleep-journal-entry-editor.component-theme'
@import '../app/modules/coaching/sleep-journal/awake-journal-entry-editor/awake-journal-entry-editor.component-theme'
@import '../app/modules/coaching/sleep-window/sleep-window-editor/sleep-window-visualiser/sleep-window-visualiser.component-theme'
@import '../app/modules/coaching/weekly-planner/weekly-planner.component-theme'
@import '../app/shared/buttons/components/add-journal-entry-button/add-journal-entry-button.component-theme'
@import '../app/modules/doc/doc.component-theme'
@import '../app/shared/sleep-rhythm/sleep-rhythm.component-theme'
@import '../app/modules/coaching/journals/diabetes-journal/diabetes-journal.component-theme'
@import '../app/modules/coaching/journals/protocols/blood-sugar-protocol/blood-sugar-protocol.component-theme'
@import '../app/modules/coaching/journals/protocols/medication-protocol/medication-protocol.component-theme'
@import '../app/modules/coaching/journals/protocols/nutrition-protocol-glykio/nutrition-protocol.component-theme'
@import '../app/modules/coaching/journals/protocols/smoke-protocol/smoke-protocol.component-theme'
@import '../app/modules/coaching/journals/protocols/weight-protocol/weight-protocol-theme'
@import '../app/modules/coaching/journals/journal-kit/journal-kit-theme.sass'
@import '../app/modules/coaching/challenge/challenge-theme'
@import '../app/modules/coaching/perception-training/perception-screen.component-theme'
@import '../app/modules/coaching/support/expert-support/expert-support.component.theme'
@import '../app/modules/coaching/coach-switcher/coach-switcher.component-theme'

@import '../app/modules/session/hanko/sign-in/sign-in.component'
@import '../app/modules/session/hanko/sign-up/sign-up.component'


// material overrides
@include mat.all-component-themes($app-theme)

// custom theme mixin to reduce repeating code
@mixin custom-components-theme($theme)

  @include mat.all-component-typographies($theme)

  // custom component themes
  @include app-component-theme($theme)
  @include session-component-theme($theme)
  @include carousel-component-theme($theme)
  @include dashboard-component-theme($theme)
  @include navigation-component-theme($theme)
  @include medication-component-theme($theme)
  @include profile-component-theme($theme)
  @include weekly-planner-component-theme($theme)
  @include license-component-theme($theme)
  @include monitor-component-theme($theme)
  @include sleep-rhyhym-component-theme($theme)
  @include doc-component($theme)
  @include header-component-theme($theme)
  @include dialog-header-component-theme($theme)
  @include module-stepper-component-theme($theme)
  @include coach-questionnaire-component-theme($theme)
  @include thryve-connection-overview-theme($theme)
  @include coach-switcher-component-theme($theme)
  @include audio-player-component-theme($theme)
  @include expert-support-component-theme($theme)

  // custom journal component themes
  @include sleep-journal-evaluation-component-theme($theme)
  @include sleep-journal-week-view-theme($theme)
  @include sleep-journal-entry-visualiser-component-theme($theme)
  @include awake-journal-week-view-component-theme($theme)
  @include hypertension-journal-component-theme($theme)
  @include add-journal-entry-button-component-theme($theme)

  @include mood-visualiser-component-theme($theme)
  @include sleep-window-visualiser-component-theme($theme)
  @include sleep-journal-entry-editor-component-theme($theme)
  @include awake-journal-entry-editor-component-theme($theme)

  @include step-manager-component-theme($theme)

  // diabetes
  @include blood-sugar-protocol-component-theme($theme)
  @include diabetes-journal-component-theme($theme)
  @include smoke-protocol-component-theme($theme)
  @include weight-protocol-component-theme($theme)
  @include medication-protocol-component-theme($theme)

  // journal kit
  @include journal-kit-theme($theme)

  // admin themes
  @include admin-theme($theme)

  // challenge themes
  @include challege-theme($theme)

  // perception theme
  @include perception-theme($theme)

  @include sign-up-theme($theme)
  @include sign-in-theme($theme)

// mementor (default) theme
.somnio-theme
  @include mat.all-component-colors($app-theme)
  @include custom-components-theme($app-theme)

  .mat-mdc-dialog-container
    border-radius: 20px
    overflow: hidden

    .mat-mdc-tab-body-content
      overflow: hidden

    @media screen and (max-width: 400px)
      border-radius: 0

  // set the default font
  font-family: 'Montserrat', Arial, sans-serif

  // set defaults for headers
  h1, h2, h3, h4, .header
    font-family: 'Baloo Chettan 2', Arial, sans-serif
    font-weight: 500

  h4
    font-family: 'Montserrat', Arial, sans-serif

  // set default for links
  a
    font-family: 'Montserrat', Arial, sans-serif
    color: map-get(legacy_colors.$mat-teal, 500)

  .mat-mdc-raised-button, .mat-mdc-button, .mat-mdc-outlined-button
    border-radius: 20px

  .mat-mdc-card
    border-radius: 20px !important
    box-shadow: 1px 1px 12px -10px rgb(0 0 0 / 50%) !important

  .mbsc-ios .mbsc-fr-btn, .mbsc-ios.mbsc-button-flat
    color: map-get(legacy_colors.$mat-teal, 500)

  .dynamic-gradient path,
  .speech-bubble path,
  .bubble path
    fill: url(#somnio-gradient) !important

  .success
    color: #319c94 !important

  .warn
    color: #ffe440 !important

  .error
    color: #ffa500 !important


// somnio junior
.somnioJunior
  @include mat.all-component-colors($somnio-junior-theme)
  @include custom-components-theme($somnio-junior-theme)

  $primary-color: m2_theming.get-color-from-palette($somnio-junior-primary, 500) !important
  $accent-color: m2_theming.get-color-from-palette($somnio-junior-accent, 500) !important
  $third-color: m2_theming.get-color-from-palette($somnio-junior-third, 500) !important

  .mat-mdc-dialog-container
    border-radius: 20px
    overflow: hidden

    .mat-mdc-tab-body-content
      overflow: hidden

    @media screen and (max-width: 400px)
      border-radius: 0

  // set the default font
  font-family: 'Montserrat', Arial, sans-serif

  // set defaults for headers
  h1, h2, h3, h4, .header
    font-family: 'Baloo Chettan 2', Arial, sans-serif
    font-weight: 500

  h4
    font-family: 'Montserrat', Arial, sans-serif

  // set default for links
  a
    font-family: 'Montserrat', Arial, sans-serif
    color: $accent-color

  .mat-mdc-raised-button, .mat-mdc-button, .mat-mdc-outlined-button
    border-radius: 20px

  .mat-mdc-card
    border-radius: 20px !important
    box-shadow: 1px 1px 12px -10px rgb(0 0 0 / 50%) !important

  .mbsc-ios .mbsc-fr-btn, .mbsc-ios.mbsc-button-flat
    color: $primary-color

  .dynamic-gradient path,
  .speech-bubble path,
  .bubble path
    fill: url(#somnio-gradient) !important

  .success
    color: $somnio-junior-success !important

  .warn
    color: m2_theming.get-color-from-palette($somnio-junior-warn, 500) !important

  .error
    color: m2_theming.get-color-from-palette($somnio-junior-error, 500) !important

  // Styles Overrides
  .go-back-button
    color: $primary-color

    .back-icon
      fill: $primary-color

  .create-account-button, .resend-activation-button
    color: $accent-color
    background: white !important
    border: 2px solid $accent-color
    box-shadow: none

  #window-wrapper
    .shift-container
      .shift-icon
        background: $accent-color

        mat-icon
          fill: white !important

  mm-sleep-journal-week-view
    #week-view
      .journal-entries
        .entry-wrapper
          .efficiency
            background: $primary-color

          .journal-entry
            .sleep-time
              background: $third-color

            .bed-time
              background: #f1f1f1 !important

          .journal-entry.active
            border-color: $primary-color

      .legend-wrapper
        .efficiency
          .marker
            background: $primary-color

        .sleep-time
          .marker
            background: $third-color

        .bed-time
          .marker
            background: #f1f1f1 !important
            border: 1px solid #707070

  mm-add-journal-entry-button
    .add-journal-entry
      .mat-icon
        color: $primary-color

  mm-sleep-journal-entry-visualiser
    #phase-visualiser
      .bed-before-time, .bed-after-time
        path
          fill: $accent-color

      .sleep
        path
          fill: $third-color

      .all-day
        path
          stroke: #707070

  mm-journal-evaluation
    .toggle-wrapper
      button
        background: none
        color: #7d7d7d !important
        border: 1px solid #eaeaea

      button.active
        color: $accent-color
        background: rgba(99, 111, 196, 0.1) !important
        border: 1px solid $accent-color

        mat-icon
          fill: $accent-color

    .property-picker.secondary
      .marker
        background: $third-color

  mm-awake-journal-week-view
    #week-view-wrapper
      .journal-entry-wrapper
        .entry-wrapper
          .journal-entry.active
            border: 2px solid $primary-color

          .journal-entry
            .propA
              background: $primary-color

            .propB
              background: $third-color

            .propC
              background: $accent-color

      .legend-wrapper
        .first
          .marker
            background: $primary-color

        .second
          .marker
            background: $third-color

        .third
          .marker
            background: $accent-color

  mm-dashboard-item
    mat-card.module-card
      border: 0 solid white

      .card-content-wrapper
        background: $somnio-junior-gradient
        border-radius: 17px

        &.inactive
          filter: grayscale(1) !important
          border-radius: 0 0 17px 17px

        .module-name, .module-name.disbaled.disbaled-color
          color: palette.$light-primary-text !important
          opacity: 1

        .start-button
          background-color: white
          color: $primary-color

        .corner-info, .corner-info.disabled.disabled-background-color
          background: $primary-color
          color: palette.$light-primary-text !important
          opacity: 1

        .buttons-wrapper
          button.disabled.disabled-background-color
            background-color: palette.$light-primary-text !important
            opacity: 1

        .mat-icon.module-completed
          padding: 5px

          svg
            fill: $primary-color

        .mat-icon.download-icon
          transform: scale(0.9) !important
          margin-left: 15px !important

          svg
            fill: white

      hr.waiting-times-hr
        display: none

      .waiting-times
        background-color: white
        border-radius: 17px 17px 0 0
        box-shadow: 1px 1px 22px -6px #707070 !important

        mat-icon.locker-icon
          fill: #707070

        .entries-to-unlock
          a
            color: $primary-color

    .highlighted-border
      border: 3px solid $primary-color

  mm-navigation
    .section
      a
        span.mdc-button__label
          color: #888888

      a.active-link
        background: m2_theming.get-color-from-palette($somnio-junior-primary, 100) !important

        mat-icon
          color: $primary-color

        span.mdc-button__label
          span
            color: $primary-color
            font-weight: bold !important

// actensio theme
.actensio

  @include mat.all-component-colors($actensio-theme)
  @include custom-components-theme($actensio-theme)

  .mat-mdc-dialog-container
    border-radius: 20px
    overflow: hidden

    @media screen and (max-width: 400px)
      border-radius: 0

  // set the default font
  font-family: 'Montserrat', Arial, sans-serif

  // set defaults for headers
  h1, h2, h3, h4, .header
    font-family: 'Baloo Chettan 2', Arial, sans-serif
    font-weight: 500

  h4
    font-family: 'Montserrat', Arial, sans-serif

  // set default for links
  a
    font-family: 'Montserrat', Arial, sans-serif
    color: map-get($actensio-purple, 500)

  .mat-mdc-raised-button, .mat-mdc-button, .mat-mdc-outlined-button
    border-radius: 20px

  .mat-mdc-card
    border-radius: 20px !important
    box-shadow: 1px 1px 12px -10px rgb(0 0 0 / 50%) !important

  .mbsc-ios .mbsc-fr-btn, .mbsc-ios.mbsc-button-flat
    color: map-get($actensio-purple, 500)

  .dynamic-gradient path,
  .speech-bubble path,
  .bubble path
    fill: url(#actensio-gradient) !important

  .success
    color: #319c94 !important

  .warn
    color: #ffe440 !important

  .error
    color: #ffa500 !important

// the glykio theme
.glykio
  --secondary-color: #7f80bb
  --secondary-light-color: rgba(127, 128, 187, 0.3)
  @include mat.all-component-colors($glykio-theme)
  @include custom-components-theme($glykio-theme)

  .mat-mdc-dialog-container
    border-radius: 20px
    overflow: hidden

    @media screen and (max-width: 400px)
      border-radius: 0

  // set the default font
  font-family: 'Montserrat', sans-serif

  input
    font-family: 'Montserrat', sans-serif

  // set defaults for headers
  h1, h2, h3, h4, .header
    font-family: 'Baloo Chettan 2', Arial, sans-serif
    font-weight: 500

  h4
    font-family: 'Montserrat', Arial, sans-serif

  // set default for links
  a
    font-family: 'Montserrat', sans-serif
    color: map-get($glykio-primary, 500)

  p
    font-family: 'Montserrat', sans-serif

  .mat-mdc-raised-button, .mat-mdc-button, .mat-mdc-outlined-button
    border-radius: 20px

  .mat-mdc-card
    border-radius: 20px
    box-shadow: 1px 1px 12px -10px rgb(0 0 0 / 50%) !important

  .mbsc-ios .mbsc-fr-btn, .mbsc-ios.mbsc-button-flat
    color: map-get($glykio-primary, 500)

  .dynamic-gradient path,
  .speech-bubble path,
  .bubble path
    fill: url(#glykio-gradient) !important

  .primary
    color: map-get($glykio-primary, 500)

  .success
    color: #a9c35d !important

  .warn
    color: #e0a216 !important

  .error
    color: #d65756 !important

  .mat-mdc-select
    width: auto

  .mat-mdc-select-trigger
    border: 1px solid map-get($glykio-primary, 500)
    border-radius: 20px
    padding: 16px 12px
    font-weight: 600
    color: map-get($glykio-primary, 500)
    height: 1.125rem

    .mat-mdc-select-arrow
      color: map-get($glykio-primary, 500)
      transform: scale(1.5)
      padding-inline: 4px

    .mat-mdc-select-value
      color: map-get($glykio-primary, 500)

  .mat-mdc-menu-panel
    border-radius: 20px
    min-height: 38px

  .mat-mdc-menu-content:not(:empty)
    padding: 0

  .mat-mdc-raised-button
    padding-top: 0.2em
    padding-bottom: 0.2em

  .mbsc-ios .mbsc-fr-w, .mbsc-font
    font-family: 'Montserrat', sans-serif

  .mm-button-tab-bar
    margin-inline: 10px

    .mat-mdc-tab-header
      border-radius: 8px
      border: 1px solid $secondary
      margin-bottom: 10px

    .mat-mdc-ink-bar
      display: none

    .mat-mdc-tab-label
      height: auto
      padding-top: 10px
      padding-bottom: 10px
      font-weight: 400
      opacity: 0.8

    .mat-mdc-tab-label-active
      background: $secondary
      color: white
      opacity: 1

    .mat-mdc-tab-label-content
      font-size: 1.2em

  button.primary-inverse
    border: 2px solid map-get($glykio-primary, 500)
    color: map-get($glykio-primary, 500)
    box-shadow: none !important

// the malio theme
.malio
  @include mat.all-component-colors($malio-theme)
  @include custom-components-theme($malio-theme)

  .mat-mdc-dialog-container
    border-radius: 20px
    overflow: hidden

    @media screen and (max-width: 400px)
      border-radius: 0

  // set the default font
  font-family: 'Montserrat', sans-serif

  // set defaults for headers
  h1, h2, h3, h4, .header
    font-family: 'Baloo Chettan 2', Arial, sans-serif
    font-weight: 500

  h4
    font-family: 'Montserrat', Arial, sans-serif

  // set default for links
  a
    font-family: 'Montserrat', sans-serif
    color: map-get($malio-primary, 500)

  .mat-mdc-raised-button, .mat-mdc-button, .mat-mdc-outlined-button
    border-radius: 20px

  .mat-mdc-card
    border-radius: 20px !important
    box-shadow: 1px 1px 12px -10px rgb(0 0 0 / 50%) !important

  .mbsc-ios .mbsc-fr-btn, .mbsc-ios.mbsc-button-flat
    color: map-get($malio-primary, 500)

  .dynamic-gradient path,
  .speech-bubble path,
  .bubble path
    fill: url(#malio-gradient) !important

  .success
    color: #319c94 !important

  .warn
    color: #ffe440 !important

  .error
    color: #ffa500 !important

// default html and body styles
html, body
  height: 100%
  scroll-behavior: smooth

// default body style
body
  font-weight: 400
  background: white
  color: #323232

// default header style
h1, h2, h3
  color: #4f4f4f

h2
  font-size: 18px

// default button style
button, a
  font-weight: 500

// default link style
a
  text-decoration: none

// global (theme independent) styles
canvas
  width: 100% !important

// always make overflow visible
svg
  overflow: visible !important

//TODO use Monseratt for me
.mat-mdc-list-text h4
  font-family: 'Monse', Arial, sans-serif

.mat-mdc-tooltip
  font-size: 15px !important

// tab group
mat-tab-group.expand
  height: 100%

  .mat-mdc-tab-header
    display: none !important

  .mat-mdc-tab-group
    height: 100% !important

  .mat-mdc-tab-body-wrapper
    height: 100% !important

.mat-mdc-snack-bar-container
  margin-bottom: max(env(safe-area-inset-bottom), 20px) !important

// admin wrapper
#admin-wrapper
  .mat-mdc-drawer-content
    overflow: auto

// margin card
.margin-card
  margin: 30px

// decrease opacity for all input underlines
.mat-mdc-form-field-underline
  opacity: 0.4 !important

// no padding class for dialogs
.no-padding .mat-mdc-dialog-container
  padding: 0 !important

.date-tab-group .mat-mdc-tab-header
  padding: 0 !important
  display: none !important

// adjust icon position in all material buttons
.mat-mdc-raised-button .mat-icon, .mat-mdc-outlined-button .mat-icon, .mat-mdc-button .mat-icon
  margin-right: 5px

.mat-sidenav-container
  height: 100% !important

.no-header .mat-mdc-tab-header
  display: none !important

.content-wrapper
  max-width: 960px
  margin-left: auto
  margin-right: auto

.bold
  font-weight: 500

// helper class for full width
.full
  width: 100%

// helper class for text align
.center
  text-align: center

.center-block
  margin-left: auto
  margin-right: auto

// helper class for text align
.right
  text-align: right

.mat-mdc-drawer-content
  height: 100%

// custom scroll bar style
::-webkit-scrollbar
  width: 6px
  height: 6px

// custom scroll bar style
::-webkit-scrollbar-track
  background: whitesmoke

// custom scroll bar style
::-webkit-scrollbar-thumb
  background: #c9c9c9
  border-radius: 10px

.mat-icon.large
  font-size: 48px !important
  height: 48px !important
  width: 48px !important

.mat-mdc-card.narrow
  max-width: 600px
  margin-left: auto
  margin-right: auto

.mat-mdc-card.flat
  border: none !important
  background: transparent !important
  box-shadow: none !important

.mat-mdc-card.no-padding
  padding: 0 !important

.control-side-nav
  mat-sidenav
    background: transparent
    width: 250px

.button-spinner
  display: inline-block !important
  margin-right: 10px

  circle
    stroke: white !important

.button-spinner-gray
  display: inline-block !important
  margin-right: 10px

  circle
    stroke: #b8b8b8 !important

button:disabled .button-spinner circle
  stroke: #b8b8b8 !important

.white-icon
  color: white !important

.mat-mdc-chip-list-wrapper
  margin: 0 !important

mat-password-strength-info mat-card
  margin-bottom: 10px

.step-condition.cdk-drag-preview
  .ql-container
    max-height: 50px !important
    overflow: hidden

// a custom slider style used all across the app
$sliderHeight: 8px

// allow for darker dialog backgrounds
.cdk-overlay-pane.gray .mat-mdc-dialog-container
  background: #f8f8f8

.mat-mdc-slider.mat-mdc-slider-horizontal
  .mat-mdc-slider-wrapper
    height: $sliderHeight

  .mat-mdc-slider-track-wrapper
    height: $sliderHeight
    border-radius: $sliderHeight

  .mat-mdc-slider-ticks-container
    .mat-mdc-slider-ticks
      border-radius: $sliderHeight
      height: $sliderHeight

  .mat-mdc-slider-track-background
    height: $sliderHeight

  .mat-mdc-slider-track-fill
    height: $sliderHeight

  .mat-mdc-slider-ticks-container
    height: $sliderHeight

// fix mobiscroll last scroll/ dropdown element not visible on firefox
@-moz-document url-prefix()
  .mbsc-sc-whl-gr-3d .mbsc-sc-whl-w, .mbsc-sc-whl-gr-3d .mbsc-sc-whl
    transform-style: flat !important

// GLOBAL ADMIN STYLES
.editor-wrapper
  border-radius: 20px
  box-shadow: 1px 1px 12px -10px rgb(0 0 0 / 50%) !important

  .editor-header
    background: #ececec
    border-top-right-radius: 20px
    border-top-left-radius: 20px
    font-size: 13px
    padding-left: 20px

  .editor-title
    padding: 0 20px

  .editor-body
    background: white
    border-bottom-right-radius: 20px
    border-bottom-left-radius: 20px
    overflow: hidden

    .custom-error
      font-size: 11px
      color: white
      padding: 5px 20px
      text-align: right
      background: orange
      font-weight: 400
      border-bottom-right-radius: 20px
      border-bottom-left-radius: 20px

.editor-wrapper.border

  .editor-header
    border: 1px solid #e0e0e0
    border-bottom: none

  .editor-body
    border: 1px solid #e0e0e0
    border-top: none

  stroke: gray
  stroke-width: 0.5px

/** has to be declared globally for mat-dialog */
.step-controller-panel
  transform: translate(-50%, -50%)

  .mat-mdc-dialog-container
    background: rgb(34 34 34 / 27%)

    .mat-mdc-dialog-surface
      background-color: transparent

      .mat-mdc-dialog-content
        padding: 7px 17px

/** Make dialogs scrollable if the size exceeds viewport height */
.mat-mdc-dialog-container
  height: auto !important

@media screen and (max-width: 415px)
  .full-width-autocomplete
    position: fixed !important
    width: 97vw !important
    left: 0 !important

/** Changes from Angular Material 15 Upgrade below */
.mat-mdc-card-actions
  justify-content: flex-end

.mat-mdc-button > .mat-icon[role='img']
  height: 24px
  width: 24px
  align-items: center
  display: flex
  justify-content: center

.mat-mdc-icon-button > .mat-icon[role='img']
  svg
    height: 100%
    width: 100%

.mat-mdc-icon-button
  height: 40px
  width: 40px
  display: flex
  align-items: center

  .mat-mdc-button-touch-target
    width: 40px
    height: 40px

.mat-mdc-option, .mat-mdc-optgroup-label
  font-size: 12px

.mdc-button__label
  display: flex
  align-items: center

.no-icon-button-translate
  mat-icon
    transform: none

.cdk-overlay-pane:has(.mat-mdc-select-panel)
  width: auto !important

.cdk-overlay-container
  button
    mat-icon
      transform: translateX(0)

.mat-mdc-card
  padding: 16px

.text-with-link
  a.underlined
    text-decoration: underline !important
    font-weight: 600 !important
