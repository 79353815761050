@use '@angular/material/core/m2/theming' as mat
@use 'styling/functions' as functions

@mixin session-component-theme($config-or-theme)

  // extract the color configuration in case a theme has been passed.
  $config: mat.get-color-config($config-or-theme)

  // extract the palettes needed from the theme definition.
  $primary: map-get($config, primary)
  $accent: map-get($config, accent)
  $warn: map-get($config, warn)
  $gradient: map-get($config-or-theme, gradient)
  $backgroundImage: map-get($config-or-theme, loginBackground)

  .color-accent
    color: mat.get-color-from-palette($accent, 500)

  // define any styles affected by the theme.
  #session-wrapper
    background: $backgroundImage
    background-image: $backgroundImage
    background-size: cover
    background-position: center center

    .stroked-button-primary
      color: mat.get-color-from-palette($primary, 500)
      border: 2px solid mat.get-color-from-palette($primary, 500)

    .lead
      font-family: map-get($config-or-theme, headlineFont)

    #tabs-wrapper
      border-radius: functions.map-deep-get($config-or-theme, boxRadius, medium)
      box-shadow: map-get($config-or-theme, shadow)

    #tabs-wrapper, .login-tab-wrapper
      .mat-mdc-raised-button, .mat-mdc-outlined-button
        border-radius: functions.map-deep-get($config-or-theme, boxRadius, large) !important

    .session-success
      color: mat.get-color-from-palette($primary, 500)
      border: 1px solid mat.get-color-from-palette($primary, 200)
      border-radius: functions.map-deep-get($config-or-theme, boxRadius, medium)

    .session-error
      color: map-get($warn, 500)
      border: 1px solid map-get($warn, 200)
      border-radius: functions.map-deep-get($config-or-theme, boxRadius, medium)



