@use '@angular/material/core/m2/theming' as mat

@mixin sleep-rhyhym-component-theme($config-or-theme)

  $config: mat.get-color-config($config-or-theme)

  $primary: map-get($config, primary)

  .fallAsleep, .fall-asleep
    background: map-get($primary, 200)
    fill: map-get($primary, 200)

  .sleep
    background: map-get($primary, 500)
    fill: map-get($primary, 500)

  .getUp, .get-up
    background: #765c81
    fill: #765c81

  .no-sleep
    background: #9B9B9B
    fill: #9B9B9B
