@use '@angular/material/core/m2/theming' as mat

@mixin expert-support-component-theme($config-or-theme)

  // extract the color configuration in case a theme has been passed.
  $config: mat.get-color-config($config-or-theme)

  // extract the palettes needed from the theme definition.
  $primary: map-get($config, primary)

  .color-primary
    color: mat.get-color-from-palette($primary, 500)

  .faq-header
    background-color: mat.get-color-from-palette($primary, 500)

  .mat-mdc-outlined-button.mat-primary
    border: 2px solid mat.get-color-from-palette($primary, 500)