@use '@angular/material/core/m2/theming' as mat
@use 'styling/functions' as functions

@mixin sleep-journal-entry-editor-component-theme($config-or-theme)

  // extract the color configuration in case a theme has been passed.
  $config: mat.get-color-config($config-or-theme)

  // extract the palettes needed from the theme definition.
  $primary: map-get($config, primary)
  $accent: map-get($config, accent)

  .bar
    font-family: map-get($config, headlineFont)

  .input-box
    .label
      font-family: map-get($config, headlineFont)

  .input
    input
      font-family: map-get($config, headlineFont)

  .save-button
    border-radius: functions.map-deep-get($config-or-theme, boxRadius, large)











