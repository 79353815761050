@use '@angular/material/core/m2/theming' as mat

@mixin sign-in-theme($config-or-theme)

  $primary: map-get($config-or-theme, primary)

  .sign-in::part(button)
    border-radius: 25px
    border-color: white
    height: 50px
    background-color: mat.get-color-from-palette($primary, 500)
    color: white
    margin-top: 6rem

  .login-wrapper
    padding: 10px 20px
    display: block

    a
      text-decoration: underline

    .create-account
      display: flex
      gap: 8px
      justify-content: center
      margin-top: 1rem

  .consent-error
    margin-bottom: 30px !important
    padding-top: 15px !important