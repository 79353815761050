@use '@angular/material/core/m2/theming' as mat
@use 'styling/functions' as functions

@mixin license-component-theme($config-or-theme)

  $gradient: map-get($config-or-theme, gradient)
  $config: mat.get-color-config($config-or-theme)
  $primary: map-get($config, primary)

  #license-wrapper

    .mat-mdc-card-image-header
      background: $gradient

      @media screen and (min-width: 900px)
        border-top-left-radius: functions.map-deep-get($config-or-theme, boxRadius, medium)
        border-top-right-radius: functions.map-deep-get($config-or-theme, boxRadius, medium)

    .license-info::first-line
      color: mat.get-color-from-palette($primary, 500)
      font-weight: 500
      font-size: 17px

    .license-upgrade-how-to
      .header
        color: mat.get-color-from-palette($primary, 500)
        font-weight: 500
        font-size: 17px



