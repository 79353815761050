@use '@angular/material/core/m2/theming' as mat

@mixin sleep-window-visualiser-component-theme($config-or-theme)

  $config: mat.get-color-config($config-or-theme)
  $primary: map-get($config, primary)
  $accent: map-get($config, accent)

  #window-visualiser

    .donut
      fill: #e6e6e6

    .sleepWindow
      fill: map-get($primary, 500)

