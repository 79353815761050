@use '@angular/material/core/m2/theming' as mat
@use 'styling/functions' as functions

@mixin monitor-component-theme($config-or-theme)

  $config: mat.get-color-config($config-or-theme)

  $primary: map-get($config, primary)
  $gradient: map-get($config-or-theme, gradient)

  #monitor-wrapper

    .mat-mdc-card-image-header
      background: $gradient

      @media screen and (min-width: 900px)
        border-top-left-radius: functions.map-deep-get($config-or-theme, boxRadius, medium)
        border-top-right-radius: functions.map-deep-get($config-or-theme, boxRadius, medium)

  .access-code-wrapper
    .access-code
      background: mat.get-color-from-palette($primary, 500)

