@use '@angular/material/core/m2/theming' as mat

@mixin weekly-planner-component-theme($config-or-theme)

  $config: mat.get-color-config($config-or-theme)

  $primary: map-get($config, primary)
  $accent: map-get($config, accent)
  $gradient: map-get($config-or-theme, gradient)

  .weekly-day-wrapper
    button
      mat-icon
        color: map-get($accent, 500)

    .primary-color
      color: map-get($primary, 500)

    .primary-background-color
      background-color: map-get($primary, 500) !important

    .plan-item.exercise
      background-color: map-get($primary, 500)

    .plan-item.next-module
      background-color: map-get($accent, 500)
