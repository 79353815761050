@use '@angular/material/core/m2/theming' as mat

@mixin medication-protocol-component-theme($config-or-theme)
  $config: mat.get-color-config($config-or-theme)
  $primary: map-get($config, primary)
  $accent: map-get($config, accent)

  /* Angular Material V <15 gives special styles for minimum values - this can be removed once we update to version >=15 */
  mm-nutrition-protocol-input-dialog
    mat-slider.mat-mdc-slider-min-value
      div.mat-mdc-slider-thumb
        background-color: map-get($primary, 500) !important

