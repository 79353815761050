@use '@angular/material/core/m2/theming' as mat

@mixin awake-journal-week-view-component-theme($config-or-theme)

  // extract the color configuration in case a theme has been passed.
  $config: mat.get-color-config($config-or-theme)

  // extract the palettes needed from the theme definition.
  $primary: map-get($config, primary)
  $accent: map-get($config, accent)

  #week-view-wrapper
    .journal-entry-wrapper

      .entry-wrapper
        .journal-entry.active
          border: 2px solid map-get($accent, 500)

        .journal-entry

          .propA
            background: map-get($primary, 500)

          .propB
            background: map-get($accent, 500)

          .propC
            background: #e2e0e0

  .legend-wrapper
    .first
      .marker
        background: map-get($primary, 500)

    .second
      .marker
        background: map-get($accent, 500)

