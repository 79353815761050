@use '@angular/material/core/m2/theming' as mat

@mixin thryve-connection-overview-theme($config-or-theme)

  $primary: map-get($config-or-theme, primary)

  mat-checkbox.colored-checkbox label div div.mat-mdc-checkbox-frame
    border-color: mat.get-color-from-palette($primary, 500)

  .usages-title
    justify-content: center
    transform: rotate(-45deg)
    transform-origin: center
    margin-right: 0 !important

  .usages-title-row
    min-height: 90px

  .thryve-usage-description
    margin: 0 20px

  .thryve-dialog-controls
    margin-top: 20px

  .thryve-connection
    align-items: center

  .info-icon
    font-size: large
    padding-left: 5px
    cursor: pointer

  #watch-icon
    margin-top: 6px

