@use '@angular/material/core/m2/theming' as mat

@mixin sign-up-theme($config-or-theme)

  $primary: map-get($config-or-theme, primary)

  .sign-up::part(button)
    border-radius: 25px
    border-color: white
    background-color: mat.get-color-from-palette($primary, 500)
    color: white

  .sign-up::part(container)
    padding: 15px

  .sign-up::part(link)
    background-color: mat.get-color-from-palette($primary, 500)
    color: mat.get-color-from-palette($primary, 500)

  .sign-up::part(paragraph)
    text-align: center
    margin-top: 2rem