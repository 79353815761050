@use '@angular/material/core/m2/theming' as mat
@use 'styling/functions' as functions

@mixin dialog-header-component-theme($config-or-theme)

  $config: mat.get-color-config($config-or-theme)

  $primary: map-get($config, primary)
  $accent: map-get($config, accent)
  $gradient: map-get($config-or-theme, gradient)

  .dialog-header
    background: map-get($primary, 500)
    border-bottom-right-radius: functions.map-deep-get($config-or-theme, boxRadius, large)
