@use '@angular/material/core/m2/theming' as mat
@use 'styling/colors/legacy-colors' as legacy_colors
@use 'styling/functions' as functions

@mixin navigation-component-theme($config-or-theme)

  // extract the color configuration in case a theme has been passed.
  $config: mat.get-color-config($config-or-theme)

  // extract the palettes needed from the theme definition.
  $primary: map-get($config, primary)
  $accent: map-get($config, accent)

  // define any styles affected by the theme.
  .section

    h2
      color: functions.map-deep-get($config-or-theme, menu, iconColor)

    .logo
      font-family: map-get($config-or-theme, headlineFont)
      color: map-get($primary, 500) !important

    a
      color: functions.map-deep-get($config-or-theme, menu, textColor)

      mat-icon
        color: functions.map-deep-get($config-or-theme, menu, iconColor) !important

    a.active-link
      background: map-get(legacy_colors.$mat-grey, 50)

      mat-icon
        color: map-get($primary, 500) !important



