@use '@angular/material/core/m2/theming' as mat

@mixin add-journal-entry-button-component-theme($config-or-theme)

  $config: mat.get-color-config($config-or-theme)

  $primary: map-get($config, primary)
  $accent: map-get($config, accent)

  .add-journal-entry
    .mat-icon
      color: map-get($accent, 500)

    &.highlighted
      background: map-get($accent, 500)

      .mat-icon
        color: white
        background: none

    &.somnio:not(.highlighted)
      .mat-icon
        color: #888
