@use 'sass:map'
@use '@angular/material/core/m2/theming' as mat

@mixin carousel-component-theme($config-or-theme)

  $config: mat.get-color-config($config-or-theme)

  $primary: map.get($config, primary)
  $accent: map.get($config, accent)
  .carousel
    position: relative
    height: 100%
    background: #ffffff
    @media screen and (max-height: 800px)
      height: 92%
    @media screen and (max-height: 700px)
      height: 90%

    .main-container
      height: 90%


    &__header
      padding: 0 20px
      border-radius: 10px
      background-size: cover
      background-position: center
      text-align: center
      height: 60%

      .text
        margin: 0
        height: 30%

      .warning
        font-weight: 600
        padding-top: 15px


        h2
          margin: 0
          padding-top: 15px
          font-size: 18px

        p
          font-size: 14px
          font-weight: 500
          margin-bottom: 5px

      .animation
        display: block
        margin-left: auto
        margin-right: auto
        padding-bottom: 0
        height: 70%


    // TODO: To be used when arrows are needed -> color needs to be updated
    .rightArrow
      position: absolute
      top: 50%
      transform: translate(0, -50%)
      right: 32px
      font-size: 45px
      color: red
      // For testing purpose
      z-index: 1
      cursor: pointer

    // TODO: To be used when arrows are needed -> color needs to be updated
    .leftArrow
      position: absolute
      top: 50%
      transform: translate(0, -50%)
      left: 32px
      font-size: 45px
      color: green
      // For testing purpose
      z-index: 1
      cursor: pointer


    .stepper
      display: flex
      justify-content: center
      padding-top: 15px

      mat-icon
        width: 20px
        font-size: 10px
        color: #ccc
        cursor: pointer
        transition: color 0.3s ease

      mat-icon.active
        color: mat.get-color-from-palette($primary, 500)


    .buttons-container
      text-align: center
      left: 0
      padding: 0 20px
      font-weight: 500

      .spacing
        margin-bottom: 14px

      .passkey-icon
        margin-bottom: 3px

    &__footer
      display: flex
      justify-content: space-between

      .back-button
        margin-top: 12px
        margin-left: 10px
        display: flex
        align-items: center

        &__icon
          transform: scale(2)


      .link-button
        background: none
        border: none
        text-decoration: underline
        cursor: pointer
        padding: 0
        font-size: inherit
        margin-right: 10px
        margin-top: 28px

@media screen and (max-width: 425px)
  .carousel
    height: 100%
    @media screen and (max-height: 700px)
      height: 95%

    @media screen and (max-height: 580px)
      height: 90%

  .main-container
    height: 92%

    @media screen and (max-height: 700px)
      height: 95%

    @media screen and (max-height: 580px)
      height: 89%

    &__header .text
      height: 20%

      @media screen and (max-height: 900px)
        height: 25%

      @media screen and (max-height: 750px)
        height: 30%

      @media screen and (max-height: 580px)
        height: 40%


  .animation
    height: 80%

    @media screen and (max-height: 900px)
      height: 75%

    @media screen and (max-height: 750px)
      height: 70%

    @media screen and (max-height: 700px)
      height: 230px

    @media screen and (max-height: 570px)
      height: 63%
