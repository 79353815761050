@use '@angular/material/core/m2/theming' as mat

@mixin app-component-theme($config-or-theme)

  // extract the color configuration in case a theme has been passed.
  // this allows consumers to either pass a theme object or a color configuration.
  $config: mat.get-color-config($config-or-theme)

  // extract the palettes you need from the theme definition.
  $primary: map-get($config, primary)
  $accent: map-get($config, accent)
  $warn: map-get($config, warn)

  // icons: gray-shades
  .icon-dark
    fill: #343434

  .icon-gray
    fill: #5b5b5b

  .icon-light-gray
    fill: #7d7d7d

  .icon-light
    fill: #888

  .icon-very-light
    color: #c9c9c9
    fill: #c9c9c9

  .icon-disabled
    color: #e2e0e0
    fill: #e2e0e0

  .icon-white
    fill: #fff

  // icons coloured
  .icon-primary
    fill: map-get($primary, 500)

  .icon-accent
    fill: map-get($accent, 500)

  .icon-warn
    fill: map-get($warn, 500)

