@use '@angular/material/core/m2/theming' as mat
@use 'styling/colors/mementor-colors' as mementor_colors

@mixin blood-sugar-protocol-component-theme($config-or-theme)
  $config: mat.get-color-config($config-or-theme)
  $primary: map-get($config, primary)
  $accent: map-get($config, accent)

  #blood-protocol
    .existing-entry-btn
      background-color: mementor_colors.$secondary-light
      border: 0
      &.active
        border: 2px solid map-get($accent, 500)
      rect
        fill: mementor_colors.$secondary

  .blood-sugar-entry-circle, .blood-sugar-entry-day-indicator
    fill: mementor_colors.$secondary
    stroke: mementor_colors.$secondary

    rect
      opacity: 0.3
