@use '@angular/material/core/m2/theming' as mat

@mixin medication-component-theme($config-or-theme)

  // extract the color configuration in case a theme has been passed.
  $config: mat.get-color-config($config-or-theme)

  // extract the palettes needed from the theme definition.
  $primary: map-get($config, primary)
  $accent: map-get($config, accent)

  #editor-wrapper
    .save-button
      background-color: map-get($primary, 500)

  .trash-button-wrapper
    border-radius: 50%

  #glykio-medication-dialog-wrapper > div
    div.label
      mat-icon
        color: map-get($primary, 500)