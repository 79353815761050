@use '@angular/material/core/m2/theming' as mat

@mixin sleep-journal-week-view-theme($config-or-theme)

  // extract the color configuration in case a theme has been passed.
  $config: mat.get-color-config($config-or-theme)

  // extract the palettes needed from the theme definition.
  $primary: map-get($config, primary)
  $accent: map-get($config, accent)

  $efficiency-color: map-get($accent, 500)
  $sleep-time-color: map-get($primary, 500)
  $bed-time-color: #f0f0f0
  
  #week-view
    .journal-entries
      .entry-wrapper

        .efficiency
          background: $efficiency-color

        .journal-entry
          .sleep-time
            background: $sleep-time-color

        .journal-entry.active
          border: 2px solid map-get($accent, 500)

    .legend-wrapper
      .efficiency
        .marker
          background: $efficiency-color

      .sleep-time
        .marker
          background: $sleep-time-color
      
      .bed-time
        .marker
          background: $bed-time-color

