@use '@angular/material/core/m2/theming' as mat
@use 'styling/colors/mementor-colors' as mementor_colors

@mixin journal-kit-theme($config-or-theme)

  $config: mat.get-color-config($config-or-theme)

  $primary: map-get($config, primary)
  $accent: map-get($config, accent)
  $warn: map-get($config, warn)

  .journal-input-box
    width: 100%
    background: white
    position: relative
    text-align: center
    overflow: hidden
    margin: 8px 0 0
    padding: 0
    border-radius: 16px
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16)

    .label
      background: mementor_colors.$secondary-light
      color: #595a5a
      padding: 15px 10px
      border-bottom: 1px solid #eaeaea
      font-weight: 500
      display: flex
      justify-content: center
      position: relative
      font-size: 1.2em

    .tip-icon
      cursor: pointer
      color: #e4e4e4
      position: absolute
      top: 50%
      transform: translateY(-50%)
      right: 20px

    .multiple-options-hint
      color: map-get($accent, 500)

    .fields-icon
      color: map-get($accent, 500)

  .journal-box-error
    padding: 10px 5px
    color: map-get($warn, 500)
    font-weight: 500
    font-size: 13px
    border-top: 1px solid #eaeaea

  // input box with error
  .journal-input-box.error
    border: 2px solid map-get($warn, 500) !important

  .journal-data-table
    .row.header
      color: map-get($primary, 500)

  .journal-multiple-table-wrapper
    display: flex
    flex-direction: column
    justify-content: center
    align-items: stretch
    gap: 1.5em

  // input box disabled
  mm-text-input.disabled
    div.label
      color: gray
      background-color: lightgray

    div.input
      input
        color: lightgray !important

      button.mat-icon-button
        color: lightgray
