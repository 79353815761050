@use '@angular/material/core/m2/theming' as mat

@mixin hypertension-journal-component-theme($config-or-theme)

  $config: mat.get-color-config($config-or-theme)

  $primary: map-get($config, primary)
  $accent: map-get($config, accent)

  #week-view
    .date-selected
      background-color: #cbbed1
      color: white

    .today
      color: mat.get-color-from-palette($accent, 500)

  .summary-item
    .value
      color: mat.get-color-from-palette($primary, 500)

  // colors for hypertension (used in week view and editor alike)
  #scrollWrapper
    .activity.low
      background: #ffc466

    .activity.mid
      background: #ffa415

    .activity.high
      background: #f67a30

    .nutrition.good
      background-color: #9fc369

    .nutrition.bad
      background-color: #d45e5c

    .dash-score.excellent
      background-color: #2f8e85

    .dash-score.good
      background-color: #9fc369

    .dash-score.ok
      background-color: #f7bc6c

    .dash-score.bad
      background-color: #f69679

    .dash-score.poor
      background-color: #d45e5c

    .relaxation.mindfulness
      background-color: #787eb9

    .relaxation.other-technique
      background-color: #b4b7da

    .stimulant.cigarettes.bad
      background-color: #d45e5c

    .stimulant.cigarettes.mid
      background-color: #ffa415

    .stimulant.cigarettes.good
      background-color: #9fc369

    .stimulant.alcohol.bad
      background-color: #d45e5c

    .stimulant.alcohol.mid
      background-color: #ffa415

    .stimulant.alcohol.good
      background-color: #9fc369

    .medication.none-taken
      background-color: #61c6c3

    .medication.some-taken
      background-color: #2f8e85

  #hypertension-overview-averages-wrapper
    .summary-item
      .value
        color: mat.get-color-from-palette($primary, 500)

  #nutrition-measurement-guideline
    mat-expansion-panel, .mat-mdc-expansion-indicator::after, mat-expansion-panel-header:hover
      background-color: mat.get-color-from-palette($primary, 500)
      color: #fff
      fill: #fff

    .mat-mdc-expansion-panel
      box-shadow: none
      border-bottom: 1px solid white

      h4
        color: mat.get-color-from-palette($primary, 300)
        opacity: 0.9

    .mat-mdc-expansion-panel-content
      background-color: #fff
      color: #222
      font-family: 'Baloo Chettan 2', sans-serif

