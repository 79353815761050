@use '@angular/material/core/m2/theming' as mat

@mixin coach-questionnaire-component-theme($config-or-theme)

  $config: mat.get-color-config($config-or-theme)

  $primary: map-get($config, primary)
  $accent: map-get($config, accent)
  $primaryLight: mat.get-color-from-palette($primary, 300)

  #questionnaire-wrapper
    .mat-mdc-button-toggle
      &.mat-mdc-button-toggle-checked
        background: mat.get-color-from-palette($primary, 500)
