@use '@angular/material/core/m2/theming' as mat

@mixin module-stepper-component-theme($config-or-theme)

  $config: mat.get-color-config($config-or-theme)

  $primary: map-get($config, primary)
  $accent: map-get($config, accent)
  $primaryLight: mat.get-color-from-palette($primary, 300)

  h2
    font-family: map-get($config-or-theme, headlineFont)
    color: mat.get-color-from-palette($primary, 500)

  .primary-color
    color: mat.get-color-from-palette($primary, 500)
    fill: mat.get-color-from-palette($primary, 500)

  .glykio-blue
    color: #7f80bb
    fill: #7f80bb

  #answer-wrapper
    ::ng-deep .next-spinner
      circle
        stroke: mat.get-color-from-palette($primary, 500) !important
