@use '@angular/material/core/m2/theming' as mat

@mixin doc-component($config-or-theme)

  $config: mat.get-color-config($config-or-theme)

  $primary: map-get($config, primary)
  $accent: map-get($config, accent)

  .doc-sub-routes

    .pdf-download-button
      color: #393939

    .pdf-download-button .icon-primary
      fill: #393939

  .controls, #evaluation-header-controls
    button
      background-color: #eee
