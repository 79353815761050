@media print
  .mat-mdc-sidenav-content
    margin-left: 0 !important

  #inner
    padding: 0 !important

  .content-wrapper
    display: contents !important

  mm-header
    visibility: collapse
