@use '@angular/material/core/m2/theming' as mat

@mixin sleep-journal-entry-visualiser-component-theme($config-or-theme)

  // extract the color configuration in case a theme has been passed.
  $config: mat.get-color-config($config-or-theme)

  // extract the palettes needed from the theme definition.
  $primary: map-get($config, primary)
  $accent: map-get($config, accent)

  #phase-visualiser
    .sleep
      path
        fill: map-get($primary, 500) !important
