@use '@angular/material/core/m2/theming' as mat
@use 'styling/colors/mementor-colors' as mementor_colors

@mixin smoke-protocol-component-theme($config-or-theme)
  $config: mat.get-color-config($config-or-theme)
  $primary: map-get($config, primary)
  $accent: map-get($config, accent)

  #smoke-protocol
    .existing-entry-btn
      background-color: mementor_colors.$secondary-light
      border: 0

      &.active
        border: 2px solid map-get($accent, 500)

      rect
        fill: mementor_colors.$secondary

  .smoke-protocol-overview
    .icon-value
      justify-content: center
      display: flex
      align-items: center
      gap: 10px
      margin: -15px 0 10px 0

      mat-icon
        fill: map-get($accent, 500)

      .text
        font-weight: 500

      .value
        font-weight: 500
        color: map-get($primary, 500)

  .off-black
    color: #525252