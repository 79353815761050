// value blot to act as a placeholder for values or function calls
.blot
    color: white
    padding: 1px 0
    font-weight: 400
    font-size: 12px
    user-select: none
    border-radius: 6px
    cursor: pointer

    span
        padding-left: 4px
        padding-right: 4px

.value-blot
    background: orange

.answer-blot
    background: #9057ff

.function-blot
    background: #e76fff

.speech-blot
    background: #ff662e

.animation-blot
    background: #5c8dff

.questionnaire-blot
    background: #5c8dff

.coach-blot
    background: #38ab53

.logic-blot
    background: #4fab58

.comparator-blot
    background: orange

.bracket-blot
    background: #4fab58

.expressionValue-blot
    background: #ff662e

.operator-blot
    background: #ff0000

.ql-editor
    line-height: 20px !important
    caret-color: black !important

quill-editor
    width: 100% !important

quill-editor > p
    overflow: hidden
    white-space: nowrap
    text-overflow: ellipsis

.ql-container.ql-snow
    border-bottom-right-radius: 20px !important
    border-bottom-left-radius: 20px !important
    border: none
    background: white
    overflow: hidden

.quill-border .ql-container.ql-snow
    border: 1px solid #ececec

.quill-wrapper
    box-shadow: 1px 1px 12px -10px rgb(0 0 0 / 50%) !important

    .quill-header
        background: #ececec
        border-bottom: none
        border-top-right-radius: 20px
        border-top-left-radius: 20px
        min-height: 40px
        line-height: 40px
        font-size: 13px
        padding-left: 10px

        h3
            margin: 0
