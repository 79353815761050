@use '@angular/material/core/m2/theming' as m2_theming;
@use '@angular/material/core/m2/typography' as m2_typography;
@use '@angular/material/core/m2/palette' as palette;
@use 'styling/colors/legacy-colors' as legacy_colors;

// colors palette
$somnio-junior-blue-color: #2c3778;
$somnio-junior-purple-color: #626fc4;
$somnio-junior-teal-color: #7ce1de;
$somnio-junior-grey-color: #353535;

$somnio-junior-blue-palette: (
    50: $somnio-junior-blue-color,
    100: rgba($somnio-junior-blue-color, 0.1),
    200: $somnio-junior-purple-color,
    300: $somnio-junior-blue-color,
    400: $somnio-junior-blue-color,
    500: $somnio-junior-blue-color,
    600: $somnio-junior-blue-color,
    700: $somnio-junior-blue-color,
    800: $somnio-junior-blue-color,
    900: $somnio-junior-blue-color,
    A100: $somnio-junior-blue-color,
    A200: $somnio-junior-blue-color,
    A400: $somnio-junior-blue-color,
    A700: $somnio-junior-blue-color,
    contrast: (
        50: palette.$dark-primary-text,
        100: palette.$dark-primary-text,
        200: palette.$dark-primary-text,
        300: palette.$dark-primary-text,
        400: palette.$light-primary-text,
        500: palette.$light-primary-text,
        600: palette.$light-primary-text,
        700: palette.$light-primary-text,
        800: palette.$light-primary-text,
        900: palette.$light-primary-text,
        A100: palette.$light-primary-text,
        A200: palette.$light-primary-text,
        A400: palette.$light-primary-text,
        A700: palette.$light-primary-text,
    ),
);

$somnio-junior-purple-palette: (
    50: $somnio-junior-purple-color,
    100: $somnio-junior-purple-color,
    200: $somnio-junior-purple-color,
    300: $somnio-junior-purple-color,
    400: $somnio-junior-purple-color,
    500: $somnio-junior-purple-color,
    600: $somnio-junior-purple-color,
    700: $somnio-junior-purple-color,
    800: $somnio-junior-purple-color,
    900: $somnio-junior-purple-color,
    A100: $somnio-junior-purple-color,
    A200: $somnio-junior-purple-color,
    A400: $somnio-junior-purple-color,
    A700: $somnio-junior-purple-color,
    contrast: (
        50: palette.$dark-primary-text,
        100: palette.$dark-primary-text,
        200: palette.$dark-primary-text,
        300: palette.$dark-primary-text,
        400: palette.$dark-primary-text,
        500: palette.$light-primary-text,
        600: palette.$light-primary-text,
        700: palette.$light-primary-text,
        800: palette.$light-primary-text,
        900: palette.$light-primary-text,
        A100: palette.$light-primary-text,
        A200: palette.$light-primary-text,
        A400: palette.$light-primary-text,
        A700: palette.$light-primary-text,
    ),
);

$somnio-junior-teal-palette: (
    50: $somnio-junior-teal-color,
    100: $somnio-junior-teal-color,
    200: $somnio-junior-teal-color,
    300: $somnio-junior-teal-color,
    400: $somnio-junior-teal-color,
    500: $somnio-junior-teal-color,
    600: $somnio-junior-teal-color,
    700: $somnio-junior-teal-color,
    800: $somnio-junior-teal-color,
    900: $somnio-junior-teal-color,
    A100: $somnio-junior-teal-color,
    A200: $somnio-junior-teal-color,
    A400: $somnio-junior-teal-color,
    A700: $somnio-junior-teal-color,
    contrast: (
        50: palette.$dark-primary-text,
        100: palette.$dark-primary-text,
        200: palette.$dark-primary-text,
        300: palette.$dark-primary-text,
        400: palette.$dark-primary-text,
        500: palette.$dark-primary-text,
        600: palette.$dark-primary-text,
        700: palette.$dark-primary-text,
        800: palette.$dark-primary-text,
        900: palette.$dark-primary-text,
        A100: palette.$dark-primary-text,
        A200: palette.$dark-primary-text,
        A400: palette.$dark-primary-text,
        A700: palette.$dark-primary-text,
    ),
);

$somnio-junior-grey-palette: (
    50: $somnio-junior-grey-color,
    100: $somnio-junior-grey-color,
    200: $somnio-junior-grey-color,
    300: $somnio-junior-grey-color,
    400: $somnio-junior-grey-color,
    500: $somnio-junior-grey-color,
    600: $somnio-junior-grey-color,
    700: $somnio-junior-grey-color,
    800: $somnio-junior-grey-color,
    900: $somnio-junior-grey-color,
    A100: $somnio-junior-grey-color,
    A200: $somnio-junior-grey-color,
    A400: $somnio-junior-grey-color,
    A700: $somnio-junior-grey-color,
    contrast: (
        50: palette.$dark-primary-text,
        100: palette.$dark-primary-text,
        200: palette.$dark-primary-text,
        300: palette.$dark-primary-text,
        400: palette.$light-primary-text,
        500: palette.$light-primary-text,
        600: palette.$light-primary-text,
        700: palette.$light-primary-text,
        800: palette.$light-primary-text,
        900: palette.$light-primary-text,
        A100: palette.$dark-primary-text,
        A200: palette.$dark-primary-text,
        A400: palette.$light-primary-text,
        A700: palette.$light-primary-text,
    ),
);

// application colors
// define-palette($color-palette, h1, h2, h3, h4)
// These four hues represent, in order: the default hue, a lighter hue, a darker hue, and a text hue
$somnio-junior-primary: m2_theming.define-palette($somnio-junior-blue-palette, A200, A100, A400);
$somnio-junior-accent: m2_theming.define-palette($somnio-junior-purple-palette, A200, A100, A400);
$somnio-junior-third: m2_theming.define-palette($somnio-junior-teal-palette, A200, A100, A400);
$somnio-junior-warn: m2_theming.define-palette(legacy_colors.$warn-orange-palette, A200, A100, A400);
$somnio-junior-success: #319c94;
$somnio-junior-error: m2_theming.define-palette(legacy_colors.$warn-orange-palette, A200, A100, A400);

// gradients
$somnio-junior-gradient: linear-gradient(135deg, $somnio-junior-purple-color 0%, $somnio-junior-teal-color 100%);

// fonts
// override default 1.25px letter spacing
$letter-spacing-normal: m2_typography.define-typography-level(
    $font-size: null,
    $line-height: null,
    $font-weight: null,
    $font-family: null,
    $letter-spacing: normal,
);
$somnio-junior-typography: m2_typography.define-typography-config(
    $font-family: 'Montserrat, sans-serif',
    $headline-5: m2_typography.define-typography-level(30px, 48px, 700),
    $button: $letter-spacing-normal,
);

// create themes
// create light (default) theme
$somnio-junior-theme: m2_theming.define-light-theme(
    (
        appColor: $somnio-junior-blue-palette,
        color: (
            primary: $somnio-junior-primary,
            accent: $somnio-junior-accent,
            warn: $somnio-junior-warn,
        ),
        typography: $somnio-junior-typography,
        gradient: $somnio-junior-gradient,
        loginBackground: #cccccc,
        // special headline font
        headlineFont: 'Baloo Chettan 2',
        boxRadius: (
            small: 5px,
            medium: 20px,
            large: 30px,
            giant: 50px,
        ),
        menu: (
            textColor: #3a3a3a,
            iconColor: #888888,
        ),
        // default shadow
        shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.15),
        login: (),
    )
);
