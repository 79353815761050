@use '@angular/material/core/m2/theming' as mat
@use 'styling/functions' as functions

@mixin profile-component-theme($config-or-theme)

  $config: mat.get-color-config($config-or-theme)

  $primary: map-get($config, primary)
  $accent: map-get($config, accent)
  $gradient: map-get($config-or-theme, gradient)

  #profile-wrapper
    .mat-mdc-card-image-header
      background: $gradient

      @media screen and (min-width: 900px)
        border-top-left-radius: functions.map-deep-get($config-or-theme, boxRadius, medium)
        border-top-right-radius: functions.map-deep-get($config-or-theme, boxRadius, medium)


    overflow: auto
    height: 100%

    .mat-mdc-card-image-header
      margin-bottom: 16px
      text-align: center
      color: white
      padding-top: max(env(safe-area-inset-top), 20px)
      padding-bottom: 20px

      mat-icon
        width: 150px
        height: 150px
        font-size: 150px

      .user-name
        font-weight: 400
        font-size: 40px

    .small-check-box
      margin-right: 5px


  .profile-card-aside
    border-right: 1px solid #e9e9e9
    @media only screen and (max-width: 450px)
      display: none !important

    mat-icon
      width: 40px
      height: 40px
      font-size: 40px

  .hanko-profile::part(headline1)
    color: mat.get-color-from-palette($primary, 500)
    font-family: "Baloo Chettan 2", Arial, sans-serif
    font-weight: 500
    font-size: 18px

  .hanko-profile::part(link)
    color: mat.get-color-from-palette($primary, 500)

  .hanko-profile::part(primary-button)
    background-color: mat.get-color-from-palette($primary, 500)
    border-color: mat.get-color-from-palette($primary, 500)
