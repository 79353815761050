@use '@angular/material/core/m2/theming' as mat

@mixin coach-switcher-component-theme($config-or-theme)

  $config: mat.get-color-config($config-or-theme)

  $primary: map-get($config, primary)
  $accent: map-get($config, accent)
  $gradient: map-get($config-or-theme, gradient)
  $headlineFont: map-get($config-or-theme, headlineFont)

  #coach-switcher-wrapper

    button .label
      font-family: $headlineFont

    button.active path
      fill: mat.get-color-from-palette($primary, 500)

    button.active .label
      border-bottom: 2px solid mat.get-color-from-palette($primary, 500) !important

  #sound-toggle.mat-mdc-white path
    fill: mat.get-color-from-palette($primary, 500) !important







