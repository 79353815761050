// Import library functions for theme creation.
@use 'sass:map'
@use '@angular/material/core/m2/theming' as mat

@mixin dashboard-component-theme($config-or-theme)

  // extract the color configuration in case a theme has been passed.
  $config: mat.get-color-config($config-or-theme)

  // extract the palettes needed from the theme definition.
  $primary: map.get($config, primary)
  $accent: map.get($config, accent)

  // define any styles affected by the theme.
  #dashboard-header

    .title
      color: mat.get-color-from-palette($primary, 500)

  mat-card
    .module-name, .multi-module-name
      color: mat.get-color-from-palette($primary, 500)

  .module-unfinished-hint
    color: mat.get-color-from-palette($accent, 300)

  .module-unfinished-dialog-button
    color: #fff
    background-color: mat.get-color-from-palette($primary, 500)

  .sub-module-list-wrapper
    .title
      color: mat.get-color-from-palette($primary, 500)
