// tiny
.icon-tn.mat-icon
  width: 10px
  height: 10px
  line-height: 5px
/* pushes it up a bit, if inline with text */

// very small
.icon-xs.mat-icon
  width: 15px
  height: 15px
  line-height: 9px
/* pushes it up a bit, if inline with text */

// small
.icon-sm.mat-icon
  width: 20px
  height: 20px
  line-height: 5px
/* pushes it up a bit, if inline with text */

// regular
.icon-rg.mat-icon
  width: 22px
  height: 22px
  line-height: 6px
/* pushes it up a bit, if inline with text */

// medium
.icon-md.mat-icon
  width: 28px
  height: 28px
  line-height: 10px
/* pushes it up a bit, if inline with text */

// large
.icon-lg.mat-icon
  width: 35px
  height: 35px
  line-height: 12px
/* pushes it up a bit, if inline with text */
