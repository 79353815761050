@use '@angular/material/core/m2/palette' as palette;

$secondary: var(--secondary-color);
$secondary-light: var(--secondary-light-color);

$warn-orange-color: #e35b00;

$mem-teal: (
    50: #e0f2f1,
    100: #b2dfdb,
    200: #80cbc4,
    300: #4db6ac,
    400: #26a69a,
    500: #319c94,
    600: #00897b,
    700: #00796b,
    800: #00695c,
    900: #004d40,
    A100: #a7ffeb,
    A200: #64ffda,
    A400: #1de9b6,
    A700: #00bfa5,
    contrast: (
        50: palette.$dark-primary-text,
        100: palette.$dark-primary-text,
        200: palette.$dark-primary-text,
        300: palette.$dark-primary-text,
        400: palette.$dark-primary-text,
        500: palette.$light-primary-text,
        600: palette.$light-primary-text,
        700: palette.$light-primary-text,
        800: palette.$light-primary-text,
        900: palette.$light-primary-text,
        A100: palette.$dark-primary-text,
        A200: palette.$dark-primary-text,
        A400: palette.$dark-primary-text,
        A700: palette.$dark-primary-text,
    ),
);

$actensio-salmon: (
    50: #f8997a,
    100: #f8997a,
    200: #f8997a,
    300: #f8997a,
    400: #f8997a,
    500: #f8997a,
    600: #f8997a,
    700: #f8997a,
    800: #f8997a,
    900: #f8997a,
    A100: #f8997a,
    A200: #f8997a,
    A400: #f8997a,
    A700: #f8997a,
    contrast: (
        50: palette.$dark-primary-text,
        100: palette.$dark-primary-text,
        200: palette.$dark-primary-text,
        300: palette.$dark-primary-text,
        400: palette.$dark-primary-text,
        500: palette.$light-primary-text,
        600: palette.$light-primary-text,
        700: palette.$light-primary-text,
        800: palette.$light-primary-text,
        900: palette.$light-primary-text,
        A100: palette.$dark-primary-text,
        A200: palette.$dark-primary-text,
        A400: palette.$dark-primary-text,
        A700: palette.$dark-primary-text,
    ),
);

$actensio-purple: (
    50: #745880,
    100: #745880,
    200: #745880,
    300: #745880,
    400: #745880,
    500: #745880,
    600: #745880,
    700: #745880,
    800: #745880,
    900: #745880,
    A100: #745880,
    A200: #745880,
    A400: #745880,
    A700: #745880,
    contrast: (
        50: palette.$dark-primary-text,
        100: palette.$dark-primary-text,
        200: palette.$dark-primary-text,
        300: palette.$dark-primary-text,
        400: palette.$dark-primary-text,
        500: palette.$light-primary-text,
        600: palette.$light-primary-text,
        700: palette.$light-primary-text,
        800: palette.$light-primary-text,
        900: palette.$light-primary-text,
        A100: palette.$dark-primary-text,
        A200: palette.$dark-primary-text,
        A400: palette.$dark-primary-text,
        A700: palette.$dark-primary-text,
    ),
);

$glykio-purple: (
    50: #eeebf0,
    100: #d6cdd9,
    200: #baacc0,
    300: #9e8ba7,
    400: #8a7294,
    500: #755981,
    600: #6d5179,
    700: #62486e,
    800: #583e64,
    900: #452e51,
    A100: #dd9eff,
    A200: #cb6bff,
    A400: #b938ff,
    A700: #b01fff,
    contrast: (
        50: #000000,
        100: #000000,
        200: #000000,
        300: #000000,
        400: #ffffff,
        500: #ffffff,
        600: #ffffff,
        700: #ffffff,
        800: #ffffff,
        900: #ffffff,
        A100: #000000,
        A200: #000000,
        A400: #ffffff,
        A700: #ffffff,
    ),
);

$glykio-orange: (
    50: #fef4e4,
    100: #fce3bd,
    200: #fbd091,
    300: #f9bd64,
    400: #f7ae43,
    500: #f6a022,
    600: #f5981e,
    700: #f38e19,
    800: #f28414,
    900: #ef730c,
    A100: #ffffff,
    A200: #fff0e6,
    A400: #ffd2b3,
    A700: #ffc49a,
    contrast: (
        50: #000000,
        100: #000000,
        200: #000000,
        300: #000000,
        400: #000000,
        500: #000000,
        600: #000000,
        700: #000000,
        800: #000000,
        900: #000000,
        A100: #000000,
        A200: #000000,
        A400: #000000,
        A700: #000000,
    ),
);

$malio-blue: (
    50: #eef3f6,
    100: #d5e2e9,
    200: #bacfda,
    300: #9ebbcb,
    400: #89adc0,
    500: #749eb5,
    600: #6c96ae,
    700: #618ca5,
    800: #57829d,
    900: #44708d,
    A100: #e5f5ff,
    A200: #b3e0ff,
    A400: #80cbff,
    A700: #66c1ff,
    contrast: (
        50: #000000,
        100: #000000,
        200: #000000,
        300: #000000,
        400: #ffffff,
        500: #ffffff,
        600: #ffffff,
        700: #ffffff,
        800: #ffffff,
        900: #ffffff,
        A100: #000000,
        A200: #000000,
        A400: #ffffff,
        A700: #ffffff,
    ),
);

$malio-purple: (
    50: #eeebf0,
    100: #d6cdd9,
    200: #baacc0,
    300: #9e8ba7,
    400: #8a7294,
    500: #755981,
    600: #6d5179,
    700: #62486e,
    800: #583e64,
    900: #452e51,
    A100: #dd9eff,
    A200: #cb6bff,
    A400: #b938ff,
    A700: #b01fff,
    contrast: (
        50: #000000,
        100: #000000,
        200: #000000,
        300: #000000,
        400: #ffffff,
        500: #ffffff,
        600: #ffffff,
        700: #ffffff,
        800: #ffffff,
        900: #ffffff,
        A100: #000000,
        A200: #000000,
        A400: #ffffff,
        A700: #ffffff,
    ),
);

$warn-orange-palette: (
    50: $warn-orange-color,
    100: $warn-orange-color,
    200: $warn-orange-color,
    300: $warn-orange-color,
    400: $warn-orange-color,
    500: $warn-orange-color,
    600: $warn-orange-color,
    700: $warn-orange-color,
    800: $warn-orange-color,
    900: $warn-orange-color,
    A100: $warn-orange-color,
    A200: $warn-orange-color,
    A400: $warn-orange-color,
    A700: $warn-orange-color,
    contrast: (
        50: palette.$dark-primary-text,
        100: palette.$dark-primary-text,
        200: palette.$dark-primary-text,
        300: palette.$dark-primary-text,
        400: palette.$light-primary-text,
        500: palette.$light-primary-text,
        600: palette.$light-primary-text,
        700: palette.$light-primary-text,
        800: palette.$light-primary-text,
        900: palette.$light-primary-text,
        A100: palette.$dark-primary-text,
        A200: palette.$dark-primary-text,
        A400: palette.$light-primary-text,
        A700: palette.$light-primary-text,
    ),
);
